@import "~smartbanner.js/dist/smartbanner.min";
.smartbanner {
  font: inherit;
  position: fixed;
  z-index: 99999;
}
.smartbanner--sb-exp {
  background-color: #f5f7fa;
  font-family: Arial, Helvetica, sans-serif;
}
.smartbanner--sb-exp .smartbanner__button {
  color: white;
  background-color: #307553;
  border-radius: 25px;
  padding: 5px 15px;
  vertical-align: middle;
  top: 24px;
}
.smartbanner--sb-exp .smartbanner__info__author {
  line-height: 13px;
  font-weight: 400;
  font-size: 11px;
}
.smartbanner--sb-exp .smartbanner__info__title {
  color: #2e3a47;
  font-weight: 700;
  line-height: 22px;
}
.smartbanner--sb-exp .smartbanner__info__price {
  margin-top: 6px;
}
.smartbanner--sb-exp .smartbanner__icon {
  width: 62px;
  height: 62px;
}
